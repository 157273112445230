import React from 'react';
import { graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import Flip from 'react-reveal/Flip';

import SEO from '../../components/seo';
import Layout from '../../components/layout';

import FeaturedPosts from '../../components/blog/featured-posts-fr';

export const query = graphql`
  query($pathSlug: String!) {
    markdownRemark(fields: { slug: { eq: $pathSlug } }) {
      html
      excerpt
      fields {
        slug
      }
      frontmatter {
        date
        title
        category
        lead
        cover {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 90) {
              ...GatsbyImageSharpFluid
            }
            resize(width: 1200, quality: 90) {
              src
            }
          }
        }
      }
    }
  }
`;

const Post = ({ data, pageContext }) => {
  const { next, prev } = pageContext;
  const { html, frontmatter, excerpt, fields } = data.markdownRemark;
  const { date, title, cover, lead, category } = frontmatter;

  return (
    <Layout>
      <SEO
        title={`${title} - Furlan Snowboards`}
        description={lead || excerpt || ' '}
        pathname={`/fr/blog${fields.slug}`}
        article
        keywords={['snowboard', 'boards', 'versatility']}
      />
      <div className="post page">
        <div className="jumbotron">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 mb-3">
                <Img fluid={cover.childImageSharp.fluid} alt={title} />
              </div>
              <div className="col-lg-10 offset-lg-1">
                <a href="/fr/blog" className="btn btn-secondary">
                  <i className="fal fa-arrow-left" />
                </a>
                <span className="badge badge-pill badge-warning ml-1">{category}</span>
                <h1 className="mt-3 mb-1">{title}</h1>
                {/*<p className="lead">{lead}</p>*/}
                {/*<small className="ml-3">{date}</small>*/}
                <div
                  className="mt-3 mb-5 post-content"
                  dangerouslySetInnerHTML={{ __html: html }}
                ></div>
              </div>
            </div>
          </div>
        </div>

        {/*
          <div className="container page-links">
            <div className="row">
              <div className="col-6">
                {prev && (
                  <Link to={prev.frontmatter.path}>
                    Previous
                    <h3>{prev.frontmatter.title}</h3>
                  </Link>
                )}
              </div>
              <div className="col-6 text-right">
                {next && (
                  <Link to={next.frontmatter.path}>
                    Next
                    <h3>{next.frontmatter.title}</h3>
                  </Link>
                )}
              </div>
            </div>
          </div>
        */}
        <FeaturedPosts />
      </div>
    </Layout>
  );
};

export default Post;

Post.propTypes = {
  pageContext: PropTypes.shape({
    prev: PropTypes.object,
    next: PropTypes.object,
  }).isRequired,
  data: PropTypes.object.isRequired,
};
